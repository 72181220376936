/* Global Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f7f9;
  margin: 0;
  padding: 0;
}

/* Container for Authentication Forms */
.auth-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 30px 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Heading Styles */
.auth-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Input Fields & Select */
.auth-container input,
.auth-container select {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.auth-container input:focus,
.auth-container select:focus {
  border-color: #007bff;
  outline: none;
}

/* Button Styles */
.auth-container button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.auth-container button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}
