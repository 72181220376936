.bento-container {
  width: 100%;
  padding: 1rem;
  background-color: #f8fafc;
}

.bento-grid {
  display: grid;
  gap: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
}

.bento-item {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background-color: #e2e8f0;
  transition: transform 0.3s ease;
  aspect-ratio: 1;
}

.featured {
  grid-column: span 2;
  grid-row: span 2;
  aspect-ratio: 1;
}

.wide {
  grid-column: span 2;
  aspect-ratio: 2/1;
}

.tall {
  grid-row: span 2;
  aspect-ratio: 1/2;
}

.wide.tall {
  aspect-ratio: 1;
}

.bento-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Image styles */
.lazy-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.lazy-image.loaded {
  opacity: 1;
}

/* Loading placeholder styles */
.image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e8f0;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f4f6;
  border-top: 3px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Hover effects */
.bento-item::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.bento-item:hover::after {
  opacity: 1;
}

/* Tablet Layout */
@media (max-width: 1024px) {
  .bento-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
  }
}

/* Mobile Layout */
@media (max-width: 768px) {
  .bento-container {
    padding: 0.75rem;
  }

  .bento-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .bento-item {
    border-radius: 8px;
  }

  /* Maintain bento layout on mobile */
  .featured {
    grid-column: span 2;
    grid-row: span 2;
  }

  .wide {
    grid-column: span 2;
  }

  .tall {
    grid-row: span 2;
  }
}

/* Very Small Screens */
@media (max-width: 480px) {
  .bento-container {
    padding: 0.5rem;
  }

  .bento-grid {
    gap: 0.5rem;
  }
} 

.hero-section {
  text-align: center;
  padding: 60px 20px 40px;
  background-color: #045a68;
  color: white;
  border-radius: 16px;
  margin-bottom: 40px;
  animation: fadeInUp 1s ease-in-out;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 12px;
}

.hero-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
