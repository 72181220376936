/* Container & Form Layout */
.mytrips-upload-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .mytrips-form {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .mytrips-form__title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #333;
  }
  
  /* Flex Rows for Grouped Inputs */
  .mytrips-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .mytrips-field-half {
    flex: 1;
    min-width: 280px;
    display: flex;
    flex-direction: column;
  }
  
  /* Field styling */
  .mytrips-label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
  /* Standard Inputs */
  .mytrips-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border 0.3s ease, box-shadow 0.3s ease;
  }
  
  .mytrips-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
  }
  
  /* Quill editor styling */
  .mytrips-editor {
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Custom File Input Wrapper */
  .mytrips-file-input-wrapper {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 8px;
  }
  
  .mytrips-input-file {
    display: none;
  }
  
  .mytrips-file-input-label {
    display: block;
    padding: 10px;
    text-align: center;
    background-color: #f7f7f7;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .mytrips-file-input-label:hover {
    background-color: #e2e2e2;
  }
  
  /* Updated Image Preview Styling */
  .mytrips-image-preview {
    margin-top: 8px;
    width: 100%;
    max-width: 280px;
    height: auto;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mytrips-image-preview:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Main Button */
  .mytrips-button {
    background-color: #007bff;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    width: 300px;
    max-width: 100%;
    margin: 20px auto 0;
    display: block;
    transition: background-color 0.3s ease;
  }
  
  .mytrips-button:hover {
    background-color: #0056b3;
  }
  
  /* Divider */
  .mytrips-divider {
    margin: 30px 0;
    border: none;
    border-top: 2px solid #e0e0e0;
  }
  
  /* Service List */
  .mytrips-list-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .mytrips-service-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  .mytrips-service-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .mytrips-service-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .mytrips-service-img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .mytrips-service-time {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 10px;
  }
  
  .mytrips-service-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .mytrips-service-desc,
  .mytrips-service-price,
  .mytrips-service-offer {
    font-size: 1rem;
    color: #555;
    margin-bottom: 8px;
  }
  
  .mytrips-button-small {
    background-color: #28a745;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    margin: 5px;
    transition: background-color 0.3s ease;
  }
  
  .mytrips-button-small:hover {
    background-color: #218838;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .mytrips-row {
      flex-direction: column;
    }
  }
  