/* BlogUpload.css */

/* Container & Layout */
.blogupload-upload-container {
    max-width: 960px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
  }
  
  /* Form Styling */
  .blogupload-form {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
  }
  
  .blogupload-form__title {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    font-weight: 600;
    color: #2c3e50;
  }
  
  /* Rows & Fields */
  .blogupload-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .blogupload-field-half {
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: column;
  }
  
  .blogupload-label {
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    font-weight: 500;
    color: #34495e;
  }
  
  /* Standard Inputs */
  .blogupload-input {
    padding: 0.75rem 1rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blogupload-input:focus {
    outline: none;
    border-color: #2980b9;
    box-shadow: 0 0 4px rgba(41, 128, 185, 0.3);
  }
  
  /* Quill Editor Styling */
  .blogupload-editor {
    height: 250px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    overflow-y: auto;
  }
  
  /* File Input */
  .blogupload-file-input-wrapper {
    position: relative;
    width: 100%;
    border: 1px dashed #bdc3c7;
    border-radius: 4px;
    overflow: hidden;
    background: #fdfdfd;
    margin-bottom: 0.5rem;
  }
  
  .blogupload-input-file {
    display: none;
  }
  
  .blogupload-file-input-label {
    display: block;
    padding: 0.75rem 1rem;
    text-align: center;
    color: #7f8c8d;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .blogupload-file-input-label:hover {
    background-color: #ecf0f1;
  }
  
  /* Image Preview */
  .blogupload-image-preview {
    display: block;
    margin: 0.5rem auto;
    max-width: 200px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Primary Button */
  .blogupload-button {
    background-color: #2980b9;
    color: #fff;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    display: block;
    margin: 1.5rem auto 0;
    transition: background-color 0.3s ease;
  }
  
  .blogupload-button:hover {
    background-color: #1c5980;
  }
  
  /* Divider */
  .blogupload-divider {
    margin: 2rem 0;
    border: none;
    border-top: 2px solid #ecf0f1;
  }
  
  /* Blog Post List */
  .blogupload-list-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #2c3e50;
  }
  
  .blogupload-post-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  .blogupload-post-card {
    background: #fff;
    border: 1px solid #ecf0f1;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
  }
  
  .blogupload-post-card:hover {
    transform: translateY(-3px);
  }
  
  .blogupload-post-icon {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 0.75rem;
  }
  
  .blogupload-post-time {
    font-size: 0.85rem;
    color: #95a5a6;
    margin-bottom: 0.5rem;
  }
  
  .blogupload-post-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #34495e;
    margin-bottom: 0.5rem;
  }
  
  .blogupload-post-desc {
    font-size: 0.95rem;
    color: #7f8c8d;
    margin-bottom: 0.75rem;
  }
  
  /* Small Action Button */
  .blogupload-button-small {
    background-color: #27ae60;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    margin: 0.25rem;
    transition: background-color 0.3s ease;
  }
  
  .blogupload-button-small:hover {
    background-color: #1e8449;
  }
  
  /* Loading & Error Messages */
  .blogupload-loading,
  .blogupload-error {
    text-align: center;
    font-size: 1rem;
    color: #e74c3c;
    margin: 1rem 0;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .blogupload-row {
      flex-direction: column;
    }
  }
  