/* Container for the entire dashboard */
.adminDashboardContainer {
    padding: 2rem;
    background-color: #f7f7f7;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  /* Dashboard title styling */
  .adminDashboardTitle {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  /* Grid layout for the admin cards */
  .adminDashboardCardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  /* Common styling for all admin cards */
  .adminDashboardCard {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    color: #333;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .adminDashboardCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon styling for cards */
  .adminDashboardCardIcon {
    font-size: 3rem;
    color: #c2031c;
    margin-bottom: 1rem;
  }
  
  /* Card title styling */
  .adminDashboardCardTitle {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  /* Card description styling */
  .adminDashboardCardDescription {
    font-size: 1rem;
  }
  
  /* Unique card classes for additional custom styling */
  .adminDashboardBookingCard {
    /* Additional custom styles for Booking Data card can be added here */
  }
  
  .adminDashboardGalleryCard {
    /* Additional custom styles for Upload Gallery card can be added here */
  }
  
  .adminDashboardBlogCard {
    /* Additional custom styles for Upload Blog card can be added here */
  }
  