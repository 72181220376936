@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


.navbar {
  position: fixed;
  top: 15px; /* maintain a top gap */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 70px;
  border-radius: 50px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease, border 0.3s ease;
  background-color: transparent;
  border: none;
  color: black;
  background-color: white;
}

/* Glassmorphic style applied after scrolling 50px */
.navbar.active {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.678);
  border: 1px solid rgba(209, 213, 219, 0.3);
  color: black;
}

.navbar-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo img {
  width: 150px;
  transition: transform 0.3s ease;
}

.navbar-logo img:hover {
  transform: scale(1.1);
}

.to-hire {
  display: flex;
  align-items: center;
}

.menu-icon {
  font-size: 28px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: none;
  transition: transform 0.3s ease;
  margin-top: 8px;
}

.menu-icon:hover {
  transform: rotate(90deg);
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-item {
  margin-left: 30px;
}

.nav-link {
  text-decoration: none;
  color: #ffc400;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease;
  font-family: "Poppins", sans-serif;
}

.nav-link:hover {
  color: #045a68;
}

.m-number-btn-main {
  width: 170px;
  height: 45px;
  background-color: #045a68;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  margin-left: 30px;
  border-radius: 60px;
}

.m-number-btn {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-menu {
    position: fixed;
    top: 70px;
    right: -110%;
    width: 100%;
    height: calc(90vh - 80px);
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.911);
    flex-direction: column;
    align-items: flex-start;
    transition: right 0.3s ease;
    padding-left: 30px;
    border-radius: 20px;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-item {
    margin: 20px 0;
  }

  .nav-link {
    font-size: 24px;
  }

  .navbar-logo img {
    width: 120px;
    transition: transform 0.3s ease;
  }
}
