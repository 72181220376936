@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300&display=swap');


.blogdisplay-container {
  min-height: 100vh;
height: 100%;
  width: 100%;
  padding: 20px 0;
 padding-top: 140px;
  background: rgb(194,3,28);
background: radial-gradient(circle, rgba(194,3,28,0.3113620448179272) 12%, rgba(9,9,121,0.6446953781512605) 84%);
}

.blogdisplay-heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  font-family: "Phudu", serif;

}

.blogdisplay-title {
  font-size: 35px;
  font-weight: bolder;
  text-align: center;
  color: #14294d;
  line-height: 10px;  /* Set line-height to 10px */
  margin: 0; /* Remove extra margins */
}

.blogdisplay-subtitle {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color:#c2031c ;
  line-height: 30px;  /* Set line-height to 10px */
  font-family: 'Poppins', serif;

  
}

.blogdisplay-list {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}

.blogdisplay-card {
  height: 380px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}

.blogdisplay-card-image {
  height: 60%;
  width: 100%;
  transition: transform 0.3s ease;
}

.blogdisplay-card:hover .blogdisplay-card-image {
  transform: scale(1.1);
}

.blogdisplay-card-content {
  height: 280px;
  width: 95%;
  background-color: #ffffff;
  position: relative;
  top: -90px;
  border-radius: 20px;
  line-height: 20px;
  text-align: start;
  padding-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, 
              rgba(0, 0, 0, 0.07) 0px 2px 4px, 
              rgba(0, 0, 0, 0.07) 0px 4px 8px, 
              rgba(0, 0, 0, 0.07) 0px 8px 16px, 
              rgba(0, 0, 0, 0.07) 0px 16px 32px, 
              rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.blogdisplay-card-title {
  margin: 40px 0px 0px 20px;
  font-family: 'League Spartan', sans-serif;
  font-weight: 400;
  color: #1d1c1c;
}

.blogdisplay-card-time {
  margin: 0 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.blogdisplay-card-icon {
  color: #c2031c;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 5px;
}

.blogdisplay-card-description {
  margin: 0 20px;
  padding-top: 10px;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: rgba(104, 103, 103, 0.877);
}

.blogdisplay-card-author {
  font-size: 14px;
  color: #666;
  margin: 0 20px;
}

.blogdisplay-card-arrow {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 20px;
  color: #c2031c;
  transition: color 0.3s;
}

.blogdisplay-card:hover .blogdisplay-card-arrow {
  color: #14294d;
}

@media screen and (max-width: 850px) {
  .blogdisplay-title {
    font-size: 28px;
  }
  .blogdisplay-subtitle {
    font-size: 12px;
  }
}
