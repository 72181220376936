.core-values-container {
  width: 100%;
  padding: 5rem 2rem;
  background-color: #f0f9ff;
}

.core-values-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.core-values-title {
  font-size: 2.5rem;
  color: #045a68;
  margin-bottom: 1.5rem;
  font-weight: 700;
  animation: fadeInDown 0.8s ease-out;
}

.core-values-description {
  max-width: 800px;
  margin: 0 auto 4rem;
  font-size: 1.1rem;
  line-height: 1.7;
  color: #555;
  animation: fadeIn 1s ease-out;
}

.mission-vision-boxes {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
  flex-wrap: wrap;
}

.mv-box {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 500px;
}

.mv-title {
  font-size: 1.5rem;
  color: #045a68;
  margin-bottom: 1rem;
  font-weight: 600;
}

.mv-text {
  font-size: 1rem;
  color: #444;
  line-height: 1.6;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  animation: fadeInUp 1.2s ease-out;
}

.value-card {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.value-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

.value-icon {
  width: 64px;
  height: 64px;
  background: #e5f7f9;
  color: #045a68;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  transition: transform 0.3s ease;
}

.value-card:hover .value-icon {
  transform: scale(1.1);
}

.value-title {
  font-size: 1.25rem;
  color: #045a68;
  margin-bottom: 1rem;
  font-weight: 600;
}

.value-description {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #64748b;
}

@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive */
@media (max-width: 1024px) {
  .values-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .values-grid {
    grid-template-columns: 1fr;
  }

  .core-values-container {
    padding: 3rem 1rem;
  }

  .core-values-title {
    font-size: 2rem;
  }

  .mv-box {
    padding: 1.5rem;
  }

  .mv-title {
    font-size: 1.25rem;
  }

  .mv-text {
    font-size: 0.95rem;
  }
}
