.appointment-enquiries-container {
    padding: 1rem;
    max-width: 1200px;
    margin: auto;
  }
  
  .action-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .action-buttons button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #c2031c;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .action-buttons button:hover {
    background-color: #dd0320;
  }
  
  .appointment-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .appointment-table th,
  .appointment-table td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
  }
  
  .appointment-table th {
    background-color: #f4f4f4;
  }
  