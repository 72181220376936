.book-appointment-container {
  min-height: 100vh;
  padding: 1rem;
  background-color: #f0f9ff;
  background-image: 
    radial-gradient(circle at 90% 10%, rgba(100, 181, 246, 0.1) 20%, transparent 40%),
    radial-gradient(circle at 10% 90%, rgba(100, 181, 246, 0.1) 20%, transparent 40%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-appointment-content {
  display: flex;
  flex-direction: column-reverse;
  max-width: 1400px;
  width: 100%;
  gap: 2rem;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
}

.appointment-card {
  background: white;
  border-radius: 24px;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.appointment-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, rgba(100, 181, 246, 0.03) 0%, transparent 70%);
  z-index: 0;
}

.appointment-card h2 {
  color: #045a68;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  position: relative;
}

.appointment-description {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  position: relative;
}

.info-section {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}

.info-content {
  max-width: 600px;
  margin: 0 auto;
}

.main-heading {
  font-size: 2rem;
  font-weight: 700;
  color: #045a68;
  line-height: 1.2;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #045a68 0%, #2aa3b6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-heading {
  font-size: 1rem;
  color: #64748b;
  line-height: 1.5;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.clinic-image {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 100%;
}

.clinic-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 24px;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.image-dots {
  position: absolute;
  width: 80px;
  height: 80px;
  background-image: radial-gradient(#3b82f6 2px, transparent 2.5px);
  background-size: 10px 10px;
  top: -20px;
  right: -20px;
  opacity: 0.3;
}

.image-stars {
  position: absolute;
  bottom: 20px;
  left: -20px;
}

.star {
  color: #3b82f6;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  opacity: 0.5;
  animation: twinkle 1.5s infinite ease-in-out;
}

.star:nth-child(2) {
  animation-delay: 0.5s;
}

.star:nth-child(3) {
  animation-delay: 1s;
}

@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

/* Rest of the existing form styles */
.appointment-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.form-group {
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 12px;
  background-color: #f8fafc;
  font-size: 1rem;
  color: #334155;
  transition: all 0.3s ease;
}

.form-group input:focus {
  outline: none;
  background-color: #fff;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  transform: translateY(-1px);
}

.form-group input::placeholder {
  color: #94a3b8;
}

.submit-button {
  background-color: #045a68;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.submit-button:hover {
  background-color: #022b31;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(14, 165, 233, 0.2);
}

.submit-button:active {
  transform: translateY(0);
}

/* Tablet and Desktop Styles */
@media (min-width: 1024px) {
  .book-appointment-container {
    padding: 2rem;
  }

  .book-appointment-content {
    flex-direction: row;
    gap: 4rem;
    padding: 2rem;
  }

  .info-section {
    text-align: left;
  }

  .main-heading {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  .sub-heading {
    font-size: 1.25rem;
    padding: 0;
    margin-bottom: 3rem;
  }

  .appointment-card {
    padding: 2.5rem;
    animation: float 6s ease-in-out infinite;
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .book-appointment-content {
    padding: 1.5rem;
  }

  .main-heading {
    font-size: 2.5rem;
  }

  .sub-heading {
    font-size: 1.125rem;
  }

  .appointment-card {
    max-width: 600px;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .book-appointment-container {
    padding: 1rem;
  }

  .book-appointment-content {
    gap: 1.5rem;
  }

  .appointment-card {
    padding: 1.5rem;
    border-radius: 20px;
  }

  .appointment-card h2 {
    font-size: 1.5rem;
  }

  .main-heading {
    font-size: 1.75rem;
    padding: 0 0.5rem;
  }

  .sub-heading {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .clinic-image {
    margin: 0 0.5rem;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
} 