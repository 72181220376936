.about-container{
  padding-top: 120px;
}
.specialists-container {
  width: 100%;
  padding: 1rem 2rem;
  background-color: #f0f9ff;
  background-image: 
    linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(240, 249, 255, 0.8) 100%);
}

.specialists-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.specialists-title {
  font-size: 2.5rem;
  color: #c2031c;
  margin-bottom: 3rem;
  font-weight: 700;
  background: linear-gradient(135deg, #c2031c 0%, #3b82f6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInDown 0.8s ease-out;
}

.specialists-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  animation: fadeInUp 1.2s ease-out;
}

.specialist-card {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.specialist-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

.specialist-image-container {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;
}

.specialist-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.specialist-card:hover .specialist-image {
  transform: scale(1.05);
}

.specialist-info {
  padding: 1.5rem;
  background: white;
}

.specialist-name {
  font-size: 1.25rem;
  color: #c2031c;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.specialist-title {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #64748b;
  margin: 0;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tablet Layout */
@media (max-width: 1024px) {
  .specialists-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .specialists-container {
    padding: 4rem 1.5rem;
  }

  .specialists-title {
    font-size: 2.2rem;
    margin-bottom: 2.5rem;
  }

  .specialist-name {
    font-size: 1.2rem;
  }

  .specialist-title {
    font-size: 0.85rem;
  }
}

/* Mobile Layout */
@media (max-width: 768px) {
  .specialists-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    max-width: 400px;
    margin: 0 auto;
  }

  .specialists-container {
    padding: 3rem 1rem;
  }

  .specialists-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .specialist-info {
    padding: 1.25rem;
  }

  .specialist-name {
    font-size: 1.1rem;
  }

  .specialist-title {
    font-size: 0.8rem;
  }

  .specialist-card:hover {
    transform: translateY(-4px);
  }
}

/* Additional hover effects */
.specialist-card::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.specialist-card:hover::after {
  opacity: 1;
} 