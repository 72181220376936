.footer {
  background-color: #ffffff;
  padding: 0rem 0;
  border-top: 1px solid #eaeaea;
  width: 94%;
  margin-left: 3%;
  margin-bottom: 40px;
  border-radius: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.footer-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Logo and Timing Section */
.footer-logo-section {
  text-align: center;
  margin-bottom: 2rem;
}

.footer-logo {
  width: 200px;
  height: auto;
  margin-bottom: 1rem;
}

.timing-text {
  color: #333;
  font-size: 1rem;
  margin: 0;
}

.timing-hours {
  color: #333;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0.25rem 0;
}

/* Social Media Links */
.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
}

.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: transform 0.2s;
}

.social-icon:hover {
  transform: translateY(-2px);
}

.social-icon.google { background-color: #DB4437; }
.social-icon.instagram { background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888); }
.social-icon.facebook { background-color: #4267B2; }
.social-icon.youtube { background-color: #FF0000; }
.social-icon.pinterest{ background-color: #045a68; }

/* Services Links */
.services-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.services-row {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.services-links a {
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  transition: color 0.2s;
}

.services-links a:hover {
  color: #045a68;
}

/* Reviews Section */
.reviews-section {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.review-box {
  background-color: #f8f9fa;
  padding: 1rem 2rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.review-count {
  text-align: center;
}

.review-count .number {
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.review-count .text {
  font-size: 0.9rem;
  color: #666;
}

.review-logo {
  height: 24px;
  width: auto;
}

.rating {
  font-size: 0.9rem;
  color: #666;
}

/* Partners Section */
.partners-section {
  margin-top: 3rem;
  text-align: center;
}

.partners-section h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.partners-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.partners-logos img {
  height: 40px;
  width: auto;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.partners-logos img:hover {
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-links {
    flex-direction: column;
    gap: 1rem;
  }

  .reviews-section {
    flex-direction: column;
    align-items: center;
  }

  .partners-logos {
    gap: 1rem;
  }

  .partners-logos img {
    height: 30px;
  }
}

/* Add separator between service links */
.services-row a:not(:last-child)::after {
  content: '|';
  margin-left: 0.5rem;
  color: #ddd;
}

/* Add Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

/* Top Contact Section */
.footer-top {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  padding: 3rem 0;
  border-radius: 30px;
  text-align: start;
}

.footer-top-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.contact-info {
  display: flex;
  gap: 2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: #c3f1f8b7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper i {
  color: #045a68;
  font-size: 1.2rem;
}

.contact-text {
  display: flex;
  flex-direction: column;
}

.contact-text .label {
  font-size: 0.8rem;
  color: #666;
  justify-content: start;
  display: flex
;
}

.contact-text a {
  color: #333;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: color 0.2s;
}

.contact-text a:hover {
  color: #045a68;
}

.slogan {
  color: #333;
  font-size: 1.1rem;
  font-weight: 500;
}

.book-button {
  background-color: #045a68;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.book-button:hover {
  background-color: #002f36;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(20, 190, 240, 0.2);
}

/* Responsive Design for Top Section */
@media (max-width: 1024px) {
  .footer-top-content {
    justify-content: center;
    text-align: center;
  }

  .contact-info {
    width: 100%;
    justify-content: center;
  }

  .slogan {
    width: 100%;
    margin: 1rem 0;
  }
}

@media (max-width: 640px) {
  .contact-info {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-item {
    justify-content: start;
  }
  .review-box{
    padding: 0px;
    background-color: white;
  }
} 
.footer-bottom {
  text-align: center;
  padding: 1rem;
  border-top: 1px solid #eaeaea;
  font-size: 0.9rem;
  color: #666;
}
