/* Overall container */
.gallery-container {
    padding: 2rem 1rem;
    background-color: #f0f0f0;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  
  /* Heading styles */
  .gallery-heading {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* Masonry layout using CSS columns */
  .gallery-masonry {
    column-count: 3; 
    column-gap: 1rem;
    padding-top: 2rem;
    transition: column-count 0.3s ease; 
  }
  

.gallery-item {
    break-inside: avoid; 
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
   
    transition: border 0.3s ease;
    border: 1px solid #ff5757;
    border-radius: 10px;


  }
  
  /* Image styles */
  .gallery-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, border 0.3s ease;
  }
  

  .gallery-item:hover .gallery-image {
    transform: scale(1.05); 
    border: 2px solid #ff5757;
  }
  
 
  .gallery-title {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 123, 255, 0.7); 
    color: #fff;
    padding: 10px 15px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px;
    transition: all 0.3s ease;
    z-index: 2; 
    opacity: 0.8; 
    text-align: left;
    width: 80%; 
    max-width: 100%;
    transform: translateY(100%); 
  }
  

  .gallery-item:hover .gallery-title {
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%; 
    background-color: #14294daf; 
    color: #fff;
    padding: 15px; 
    opacity: 1; 
    text-align: center; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    transform: translateY(0); 
  }
  
/* g-hero Section */
.g-hero-section {
  position: relative;
  width: 100%;
  height: 70vh; 
  background-image: url(''); 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  overflow: hidden;
  padding: 0 0px;
}

.g-hero-section::before {
  content: '';
  position: absolute;
  inset: 0;
  background: #c2031c; 
  z-index: 1;
}

.g-hero-content {
  position: relative;
  z-index: 2; 
  animation: fadeIn 2s ease-in-out; 
}

.g-hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  letter-spacing: 1px;
  animation: slideInDown 1s ease-out;
}

.g-hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: slideInUp 1.2s ease-out;
}

.g-hero-button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  background: #ff5757;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  animation: fadeIn 2.2s ease-out;
}

.g-hero-button:hover {
  background: #0056b3;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@media (max-width: 768px) {
  .g-hero-title {
    font-size: 2.5rem;
  }
  .g-hero-subtitle {
    font-size: 1.2rem;
  }
  .g-hero-button {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
}

  @media (max-width: 1024px) {
    .gallery-masonry {
      column-count: 2; 
    }
  }
  
  @media (max-width: 760px) {
    .gallery-image {
      width: 100%; 
      height: auto; 
    }
  
    .gallery-masonry {
      column-count: 1; 
    }
  
    .gallery-heading {
      font-size: 1.8rem; 
    }
  
    .gallery-item {
      margin-bottom: 1.5rem; 
    }
  
    .g-hero-title {
      font-size: 2.2rem; 
    }
  
    .g-hero-subtitle {
      font-size: 1.2rem; 
    }
  
    .g-hero-button {
      padding: 0.5rem 1.2rem; 
      font-size: 0.9rem;
    }
  }
  
  