.testimonial-section {
  padding: 4rem 2rem;
  background-color: #045a68;
  color: white;
  display: flex;
  flex-direction: column;
}

.testimonial-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #fff;
  font-family: "Phudu", serif;

}

@media (min-width: 824px) {
  .testimonial-section {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  .testimonial-stats {
    width: 30%;
    flex-shrink: 0; 
    margin-bottom: 0;
  }

  .testimonial-slider-container {
    width: 70%;
  }
}

.testimonial-stats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 3rem;
}

.stats-box {
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
}

.stats-box h2 {
  font-size: 2.5rem;
  margin: 0;
  color: #fff;
  
}

.stats-box p {
  margin: 0.5rem 0;
  color: #94a3b8;
}

.platform-logo {
  height: 80px;
  margin: 0.5rem 0;
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.stars {
  color: #fbbf24;
  letter-spacing: 2px;
}

.testimonial-slider {
  padding: 1rem 0;
}

.testimonial-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 2rem;
  min-width: 260px;
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.testimonial-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.user-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
}

.user-info .role {
  margin: 0.25rem 0;
  color: #94a3b8;
  font-size: 0.9rem;
}

.testimonial-text {
  color: #94a3b8;
  line-height: 1.6;
  margin: 0;
  flex-grow: 1;
}

/* Divider with full width */
.testimonial-divider {
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  margin: 1rem 0;
}

/* Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.1);
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.2rem !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-stats {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-section {
    padding: 2rem 1rem;
  }
}

/* Add hover effects */
.testimonial-card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
  background-color: rgba(255, 255, 255, 0.08);
}
