.carousel-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.carousel-slide {
  position: relative;
}

/* Ensure the slide image fills the viewport */
.carousel-slide img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

/* Scale image slightly on mouse hover for a micro interaction */
.carousel-slide:hover img {
  transform: scale(1.05);
}

/* Optional overlay if needed */
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

/* Centered text overlay */
.carousel-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #e6c658;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-text-overlay.active {
  opacity: 1;
}

/* Flip animation keyframes for headings */
@keyframes flipInX {
  from {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotateX(0deg);
  }
}

/* Apply the flip animation only on active headings */
.carousel-text-overlay.active h2 {
  animation: flipInX 1s both;
}

/* Text styling */
.carousel-text-overlay h2 {
  font-family: "Phudu", serif;
  font-weight: 700;
  line-height: 60px;
  font-size: 56px;
  margin-bottom: 10px;
}

.carousel-text-overlay p {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 300;
}

.carousel-button {
  padding: 18px 32px;
  font-size: 20px;
  background-color: #045a68;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Additional micro interaction for button hover */
.carousel-button:hover {
  background-color: #002c33;
  transform: scale(1.05);
}

/* Decorative SVG styling */
.svg-decorator {
  position: absolute;
  z-index: 10;
  transition: transform 0.2s ease-out;
}

/* Font imports */
@import url('https://fonts.googleapis.com/css2?family=Phudu:wght@300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.hero-heading-color {
  color: #b88f09;
  font-family: "Phudu", serif;
  font-weight: 800;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .carousel-text-overlay {
    width: 90%;
    padding: 15px;
  }

  .carousel-text-overlay h2 {
    font-size: 28px;
    line-height: 30px;
  }

  .carousel-text-overlay p {
    font-size: 14px;
  }

  .carousel-button {
    font-size: 17px;
    padding: 11px 25px;
  }

  /* Adjust SVG sizes on smaller screens */
  .svg-decorator svg {
    width: 40px;
    height: 40px;
  }
}
