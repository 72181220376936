

/* Main container */
.whychooseus-main {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
}

/* ------------------- */
/* Hero Section        */
/* ------------------- */

.whychooseus-hero {
  background: #045a68;
  padding: 3rem 1rem;
  text-align: center;
}

.whychooseus-hero-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

}

@media (min-width: 992px) {
  .whychooseus-hero-container {
    flex-direction: row;
    align-items: center;
  }
}

.whychooseus-hero-text {
  flex: 1;
}

.whychooseus-title {
  font-size: 3rem;
  font-weight: bold;
  color: #e2b520;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-family: "Phudu", serif;



}

.whychooseus-highlight {
  color: #14294d;
}

.whychooseus-description {
  font-size: 1rem;
  color: white;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.whychooseus-button {
  padding: 0.75rem 1.5rem;
  background-color: #057283;
  color: #ffffff;
  border: none;
  border-radius: 999px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.whychooseus-button:hover {
  background-color: rgba(10, 31, 68, 0.9);
}

/* Hero Image */
.whychooseus-hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.whychooseus-hero-image img {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  object-fit: cover;
}

/* ------------------- */
/* Features Section    */
/* ------------------- */

.whychooseus-features {
  background-color: #f5fbfd;
  padding: 3rem 1rem;
}

.whychooseus-features-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (min-width: 992px) {
  .whychooseus-features-container {
    flex-direction: row;
    align-items: flex-start;
  }
}

/* Clinic Image */
.whychooseus-features-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whychooseus-features-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  object-fit: cover;
}

/* Cards container */
.whychooseus-features-cards {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Each card is a row: icon 25%, text 75% */
.feature-card-row {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #e8f5f9; /* Subtle border */
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Icon container: 25% of card width */
.feature-card-icon-container {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5fbfd; /* Light background behind icon */
}

.feature-card-icon {
  font-size: 48px;
  color: #045a68;
}

/* Text container: 75% of card width */
.feature-card-text {
  width: 75%;
  padding: 1.5rem;
  text-align: start;
}

.feature-card-title {
  font-size: 1.125rem; /* ~18px */
  font-weight: bold;
  color: #045a68;
  margin-bottom: 0.5rem;
}

.feature-card-text p {
  font-size: 0.95rem; /* ~15px */
  line-height: 1.5;
  color: #4a5568;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .whychooseus-title {
    font-size: 1.5rem;
  }

  .whychooseus-description {
    font-size: 0.9rem;
  }

  .whychooseus-button {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .feature-card-icon {
    font-size: 36px;
  }

  .feature-card-title {
    font-size: 1rem;
  }

  .feature-card-text p {
    font-size: 0.85rem;
  }
}
