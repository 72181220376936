@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300&display=swap');

/* Container for the entire blog details page */
.blogdetail-container {
  margin-bottom: 100px;
  font-family: Arial, sans-serif;
  text-align: start;
  padding: 20px;
  margin-top: 80px;
}

/* Hero section including breadcrumb and title */
.blogdetail-hero {
  background: #f5f9fa;
  padding: 20px;
}

/* Breadcrumb container */
.blogdetail-breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #808080;
  margin-bottom: 10px;
  list-style-type: none;
}
.blogdetail-author {
    font-size: 16px;
    font-weight: 500;
    color: #555;
    margin: 10px 20px;
  }

.blogdetail-breadcrumb-link {
  text-decoration: none;
  color: inherit;
}

.blogdetail-breadcrumb-item {
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.blogdetail-breadcrumb-item:hover {
  color: #3cbd8a;
}

.blogdetail-breadcrumb-separator {
  font-size: 14px;
}

.blogdetail-breadcrumb-active {
  font-weight: bold;
  color: #1d1c1c;
}

/* Title hero area */
.blogdetail-title-hero {
  margin: 10px 15px;
}

.blogdetail-title {
  font-size: 28px;
  font-weight: 700;
  color: #1d1c1c;
}

/* Content section container */
.blogdetail-content {
  display: flex;
  gap: 20px;
  padding: 20px;
}

/* Left column: main blog details */
.blogdetail-main {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.blogdetail-image-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.blogdetail-image {
  width: 100%;
  display: block;
}

.blogdetail-text {
  margin: 20px 0;
}

.blogdetail-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1d1c1c;
}

.blogdetail-description {
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
}

.blogdetail-body {
  font-size: 16px;
  color: #333;
}

/* Right column: sidebar for other posts */
.blogdetail-sidebar {
  width: 27%;
  height: 100%;
  min-height: 100vh;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blogdetail-sidebar-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #1d1c1c;
}

/* Sidebar list container */
.blogdetail-sidebar-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Each card in the sidebar list */
.blogdetail-card {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.blogdetail-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Card image */
.blogdetail-card-image {
  width: 120px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

/* Card data container */
.blogdetail-card-data {
  margin-left: 10px;
  flex: 1;
}

.blogdetail-card-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px;
  color: #1d1c1c;
}

.blogdetail-card-time {
  font-size: 14px;
  color: #808080;
  display: flex;
  align-items: center;
}

.blogdetail-card-icon {
  margin-right: 5px;
}

/* Responsive adjustments */
@media screen and (max-width: 750px) {
  .blogdetail-content {
    flex-direction: column;
  }
  
  .blogdetail-main,
  .blogdetail-sidebar {
    width: 100%;
  }
  
  .blogdetail-title {
    font-size: 22px;
  }
}

.blogdetail-sidebar-link {
  text-decoration: none;
}
.blogdetail-body img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  