@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.welcome-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  background: linear-gradient(135deg, #f0f4ff, #e8efff);
  
}

.welcome-text {
  flex: 0 0 55%;
  padding: 20px;
  font-family: "Phudu", serif;
 
  color: #e2b318;
}

.welcome-text h1 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
}

.welcome-text p {
  font-size: 18px;
  line-height: 1.6;
  color: #444;
  font-family: 'Poppins', serif;
}

.welcome-animation {
  flex: 0 0 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .welcome-container {
    flex-direction: column;
    text-align: center;
  padding: 0px 7px;

    
  }
  .welcome-text,
  .welcome-animation {
    flex: 0 0 auto;
    width: 100%;
  }
  .welcome-text h1 {
    font-size: 36px;
  }
  .welcome-text p {
    font-size: 16px;
  }
  .welcome-animation {
    padding: 2px;
    margin-top: -5%;
  }
}
