.container {
  width: 100%;
  padding: 0.5rem;
  background-color: #f0f9ff;
}

.grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
}

.card {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 220px;
  width: 320px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 60px;
  height: 60px;
  background: #f0f9ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.icon span {
  font-size: 1.8rem;
}

.title {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.details {
  margin: 0;
  color: #64748b;
  font-size: 1rem;
  line-height: 1.5;
}

.link {
  color: #64748b;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link:hover {
  color: #c2031c;
}

/* Tablet Layout */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 1.5rem 0;
  }

  .card {
    padding: 1.5rem;
  }
}

/* Mobile Layout */
@media (max-width: 768px) {
  .container {
    padding: 1.5rem 1rem;
  }

  .grid {
    grid-template-columns: 1fr;
    max-width: 400px;
  }

  .card {
    padding: 1.5rem;
  }

  .icon {
    width: 50px;
    height: 50px;
  }

  .icon span {
    font-size: 1.5rem;
  }

  .title {
    font-size: 1.2rem;
  }

  .details {
    font-size: 0.95rem;
  }
} 