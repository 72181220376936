.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 50%;
    
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s;
    animation: glow 2s ease-in-out infinite;
    width: 50px;
    height: 50px;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.1);
  }
  
  /* Enhanced glow-up animation */
  @keyframes glow {
    0% {
      box-shadow: 0 0 5px rgba(37, 211, 102, 0.5);
    }
    50% {
      box-shadow: 0 0 25px rgba(37, 211, 102, 1);
    }
    100% {
      box-shadow: 0 0 5px rgba(37, 211, 102, 0.5);
    }
  }
  