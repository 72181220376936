/* ServiceGrid.css */

/* --- General Container and Title --- */
.service-grid-container {
  padding: 5rem 1rem; /* More vertical padding */
  background-color: #f8f9fa; /* Light background for the section */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Professional font */
}

.service-grid-title {
  font-size: 2.8rem; /* Slightly larger title */
  font-weight: 700;
  text-align: center;
  margin-bottom: 4rem; /* More space below title */
  color: #2c3e50; /* Darker, less saturated blue/grey */
  position: relative;
  font-family: "Phudu", serif; /* Keep user's title font if desired */
}

.service-grid-title::after {
  content: '';
  position: absolute;
  bottom: -15px; /* Lower the underline slightly */
  left: 50%;
  transform: translateX(-50%);
  width: 80px; /* Wider underline */
  height: 4px;
  /* Updated gradient to match potential new palette */
  background: linear-gradient(90deg, #f39c12, #3498db); /* Example: Orange to Blue */
  border-radius: 2px;
}

/* --- Service Cards Wrapper --- */
.service-cards-wrapper {
  display: grid; /* Use Grid for layout */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 2.5rem; /* Space between cards */
  max-width: 1200px; /* Allow wider layout */
  margin: 0 auto; /* Center the grid */
}

/* --- Individual Service Card --- */
.service-card {
  background-color: #ffffff; /* White card background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08); /* Softer, more prominent shadow */
  overflow: hidden; /* Keep image corners rounded */
  display: flex;
  flex-direction: column; /* Stack image and content */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Hover effect */
  opacity: 0; /* Start hidden for animation */
  /* No border needed with shadow */
}

/* Card Hover Effect */
.service-card:hover {
  transform: translateY(-5px); /* Lift card slightly */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12); /* Enhance shadow on hover */
}

/* Apply fade-in animation to the card */
.service-card.fade-in-up {
  animation: fadeInUp 0.6s ease forwards;
}

/* --- Card Image --- */
.service-card-image {
  width: 100%;
  height: 200px; /* Fixed height for consistency */
  object-fit: cover; /* Cover the area without distortion */
  display: block; /* Remove extra space below image */
  border-bottom: 1px solid #eee; /* Subtle separator */
}

/* --- Card Content --- */
.service-card-content {
  padding: 1.5rem 1.8rem; /* Generous padding */
  flex-grow: 1; /* Allow content to fill remaining space if needed */
  display: flex;
  flex-direction: column;
}

/* --- Card Category Title (Heading) --- */
.service-card-category {
  font-size: 1.5rem; /* Larger category title */
  font-weight: 600;
  color: #34495e; /* Slightly softer dark color */
  margin-bottom: 1rem; /* Space below title */
  text-align: left; /* Align left for professional look */
}

/* --- Card Items List (Subheadings) --- */
.service-card-items {
  list-style: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
  margin-top: auto; /* Push list towards bottom if card height varies */
  text-align: left;
}

.service-card-item {
  font-size: 0.95rem;
  color: #555; /* Standard text color */
  margin-bottom: 0.6rem; /* Space between items */
  line-height: 1.5; /* Improve readability */
  position: relative;
  padding-left: 1.4rem; /* Space for custom bullet */
}

/* Custom bullet point for elegance */
.service-card-item::before {
  content: '✓'; /* Checkmark or use '•' for bullet */
  position: absolute;
  left: 0;
  top: 1px;
  color: #3498db; /* Accent color for the bullet */
  font-weight: bold;
  font-size: 1rem;
}

/* Last item no margin bottom */
.service-card-item:last-child {
  margin-bottom: 0;
}


/* --- Keyframes Animation (Unchanged) --- */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px); /* Slightly larger initial offset */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* --- Animation Delays (Staggering) --- */
/* Apply delays based on grid position - more complex but works */
/* You might need more complex JS logic for perfect row-by-row stagger in grid */
/* Simple nth-child is okay for initial load */
.service-card:nth-child(1) { animation-delay: 0.1s; }
.service-card:nth-child(2) { animation-delay: 0.2s; }
.service-card:nth-child(3) { animation-delay: 0.3s; }
.service-card:nth-child(4) { animation-delay: 0.15s; } /* Stagger next row */
.service-card:nth-child(5) { animation-delay: 0.25s; }
.service-card:nth-child(6) { animation-delay: 0.35s; }
/* Adjust delays as needed based on typical grid items per row */


/* --- Responsive Adjustments --- */
@media (max-width: 992px) {
  .service-cards-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
  }
    .service-grid-title {
    font-size: 2.5rem;
  }
}


@media (max-width: 768px) {
  .service-grid-title {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }
   .service-cards-wrapper {
    gap: 1.5rem;
  }
  .service-card-content {
    padding: 1.2rem 1.5rem;
  }
  .service-card-category {
    font-size: 1.3rem;
  }
  .service-card-item {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
 .service-cards-wrapper {
    /* Stack cards vertically on very small screens */
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
   .service-grid-title {
    font-size: 1.9rem;
  }
  .service-card-image {
     height: 180px; /* Slightly smaller image */
  }
}