/* LightDisplay.module.css */

.displayContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px; /* Increased gap */
    padding: 50px 40px; /* Increased padding */
    font-family: 'Montserrat', sans-serif; /* Example modern, bold font */
    min-height: 70vh; /* Increased min-height */
    width: 100%;
    box-sizing: border-box;
    background-color: #045a68; /* Keep the background color */
  }
  
  /* Ensure Font is loaded (Add in your main index.html or index.css)
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
  */
  
  .lightColumn {
   
    flex-shrink: 0;
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 30%;
  }
  
  .controlsColumn {
    
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
  }
  
  /* --- New Heading Styles --- */
  .headingContainer {
    margin-bottom: 15px; /* Add space below heading block */
    width: 100%; /* Take full width of column */
  }
  
  .headingLine {
    display: block; /* Each span on its own line */
    font-weight: 900; /* Very bold weight */
    font-size: 2.8rem; /* Large font size */
    line-height: 1.1; /* Tighten line spacing */
    text-transform: uppercase;
    letter-spacing: 1px; /* Slight spacing */
    font-family: "Phudu", serif;
  }
  
  .colorGold {
    color: #eeba2b; /* Golden yellow from reference */
  }
  
  .colorTeal {
    color: #0b6971; /* Teal from reference */
  }
  
  /* --- New Paragraph Styles --- */
  .subParagraph {
    font-size: 1rem; /* Standard readable size */
    color: #d8e2e4; /* Lighter grey/cyan for contrast */
    line-height: 1.7; /* Comfortable line spacing */
    
    font-weight: 400; /* Regular weight */
    margin: 0; /* Remove default margins */
  }
  
  /* --- Switch Styles Wrapper (for spacing) --- */
  .switchWrapper {
      margin-top: 20px; /* Add specific space above switch */
  }
  
  /* --- Realistic Switch Styles (keep as before, check contrast) --- */
  .switchTrack {
    background-color: #78909c;
    width: 70px;
    height: 34px;
    border-radius: 17px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    border: 1px solid #546e7a;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.3);
  }
  
  .switchKnob {
    background-color: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 3px;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 1px 4px rgba(0,0,0,0.4);
  }
  
  .switchTrack.on {
    background-color: #81c784;
    border-color: #4caf50;
  }
  
  .switchKnob.on {
    transform: translateX(36px);
  }
  
  .switchTrack:focus-visible {
      outline: 2px solid #4fc3f7;
      outline-offset: 2px;
  }
  

  @media (max-width: 768px) {
    .displayContainer {
      flex-direction: column; 
      gap: 10px;
      padding: 30px 15px;
    }
  
    .lightColumn {
      flex-basis: auto; 
      width: 80%; 
      max-width: 300px; 
    }
  
    .controlsColumn {
      flex-basis: auto;
      width: 90%;
      max-width: 450px; 
    }
  
    .headingLine {
      font-size: 1.5rem; 
    }
  
    .subParagraph {
      font-size: 0.95rem;
    }
  }