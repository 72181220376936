/* Facts Container 
   - 4 columns on larger screens
   - 2 columns on mobile (under 768px) */
   .facts-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    padding: 2rem;
    background-color: #045a68; /* Light background */
  }
  
  @media (max-width: 768px) {
    .facts-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Each Fact Item */
  .fact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
 
 
  /* Icon styling */
  .fact-icon {
    font-size: 3rem;
    color: #e2b520; /* Dark navy */
  }
  
  /* Text container */
  .fact-info {
    display: flex;
    flex-direction: column;
  }
  
  /* Count-Up Number */
  .fact-count {
    font-size: 2rem;
    font-weight: 700;
    color: #e2b520;
  }
  
  /* Label under the Count */
  .fact-label {
    font-size: 1rem;
    color: #e2b520;
    margin-top: 0.25rem;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .fact-icon {
      font-size: 2rem;
    }
  
    .fact-count {
      font-size: 1.5rem;
    }
  
    .fact-label {
      font-size: 0.9rem;
    }
  }
  