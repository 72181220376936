/* PendantLights.module.css */

.pendantContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    width: 300px;
    margin: auto;
  }
  
  .svgCanvas {
    width: 100%;
    height: auto;
    overflow: visible; /* Important for seeing the glow outside the element bounds */
  }
  
  .ceilingMount {
    fill: #1a1a1a;
  }
  
  /* --- New class for the group containing wires and rings --- */
  .movingParts {
    /* Apply the animation here */
    animation: sway 7s ease-in-out infinite;
    /* Define the pivot point for the rotation - corresponds to mountCx, mountCy */
    /* Adjust these pixel values if your viewBox or mount position changes */
    transform-origin: 150px 40px; /* transform-origin: x y; */
  }
  
  .wire {
    stroke: #333;
    stroke-width: 0.5;
  }
  
  .ringDark {
    fill: none;
    stroke: #1a1a1a;
    stroke-width: 6;
  }
  
  .ringLight {
    fill: none;
    stroke-width: 5;
    transition: stroke 0.3s ease-in-out, filter 0.3s ease-in-out;
  }
  
  .ringLight.on {
    stroke: #ffffff;
    filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.7));
  }
  
  .ringLight.off {
    stroke: #4d4d4d;
    filter: none;
  }
  
  .toggleButton {
    margin-top: 20px;
    padding: 8px 15px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .toggleButton:hover {
    background-color: #ddd;
  }
  
  .toggleButton:active {
    background-color: #ccc;
  }
  
  
  /* --- Keyframes for the swaying animation --- */
  @keyframes sway {
    0% {
      transform: rotate(0deg); /* Start position */
    }
    25% {
       transform: rotate(1.5deg); /* Swing gently right */
    }
    75% {
       transform: rotate(-1.5deg); /* Swing gently left */
    }
    100% {
       transform: rotate(0deg); /* Return to center */
    }
   /* Alternate version for a slightly different feel:
    0% {
      transform: rotate(-1.5deg);
    }
    50% {
       transform: rotate(1.5deg);
    }
    100% {
       transform: rotate(-1.5deg);
    }
   */
  }